import { createResource } from 'solid-js';
import { merge } from 'rambda';
import WebApp from '@twa-dev/sdk';

import { isDateEqual } from 'shared/units/date';

import type { TranslationDocument as Translation } from '../schematic/translation.schema';
import { defaultProcessors } from '../schematic/processors';
import { createTranslator } from '../schematic';

/**
 * Accessor for a current browser language
 */
export const currentLocale = () => new Intl.Locale(
    WebApp.initDataUnsafe.user?.language_code
    ?? (import.meta.env.DEV ? 'ru' : window.navigator.language)
);

export interface LocaleResponse<Locale> {
  default: Locale;
  remote?: Promise<Locale | undefined>;
}

export const createLocaleResource = <Locale extends Translation>(
  localeImport: (lang: Intl.Locale) => Promise<LocaleResponse<Locale>>,
  locale = currentLocale
) => {
  const [localeResource, { mutate }] = createResource(
    () => localeImport(locale()),
    (localeData) => localeData.then(r => {
      r.remote?.then(loc => {
        if (loc) {
          mutate((prev) => merge(prev, loc));
        }
      });

      return r.default;
    })
  );

  return createTranslator(localeResource, locale, {
    ...defaultProcessors,

    'relative-date': lang => {
      const formatLocale = defaultProcessors.date(lang);

      return (options: Intl.DateTimeFormatOptions, key: string) => {
        const format = formatLocale(options, key);

        return (input: {
            today: string;
            tomorrow: string;
            afterTomorrow?: string;
            yesterday: string;
            date: string | Date;
          }) => {

            const date = new Date(input.date);
            const today = new Date();
            const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
            const afterTomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2);
            const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

            return format((
                isDateEqual(date, today) ? input.today
              : isDateEqual(date, tomorrow) ? input.tomorrow
              : isDateEqual(date, yesterday) ? input.yesterday
              : isDateEqual(date, afterTomorrow) ? input.afterTomorrow
              : input.date.toString()
            ) ?? input.date.toString()
          );
        };
      };
    },
  });
};
