// @refresh reload
import {
  Body,
  ErrorBoundary,
  FileRoutes,
  Head,
  Html,
  Meta,
  Routes,
  Scripts,
  Title,
} from 'solid-start';
import { Suspense, onMount } from 'solid-js';
import WebApp from '@twa-dev/sdk';
import { Link, MetaProvider } from '@solidjs/meta';

import { displayName, description } from '../package.json';
import './css';

import SW from './SW';

export default function Root() {
  WebApp.expand();

  onMount(() => {
    document.documentElement.style.colorScheme = WebApp.colorScheme;
  });

  return (
    <Html lang={WebApp.initDataUnsafe.user?.language_code ?? window.navigator.language}>
      <Head>
        <Title>{displayName}</Title>
        <Meta charset="utf-8" />
        <Meta name="title" content={displayName}/>
        <Meta name="description" content={description}/>
        <Meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no" />
        <Meta name="robots" content="noindex, nofollow" />
        <Link rel="manifest" href="manifest.json" />
      </Head>
      <Body>
        <Suspense>
          <ErrorBoundary>
            <MetaProvider>
              <Routes>
                <FileRoutes />
              </Routes>
            </MetaProvider>
          </ErrorBoundary>
        </Suspense>
        <Scripts />
        <SW />
      </Body>
    </Html>
  );
}
