import type { TranslationModule } from './ts.schema';
import type { InputObject, ParameterObject, ParametrizedTranslationRecord } from './translation.schema';
import { defaultProcessors } from './processors';
import type { Processor, InputParameter, Processors, OptionsParameter } from './processors';

export type Translation = TranslationModule;

export type LocaleKey<Locale extends Translation> = Extract<keyof Omit<Locale, '$schema'>, string>;

type ExtraPartial<I> = {
  [P in keyof I]?: I[P] | null | undefined;
};

export type LocaleInputParameter<
  Locale extends Translation,
  K extends LocaleKey<Locale>,
  P extends Processors,
> = null | (
  Locale[K] extends { processor: infer O; input: infer I; }
    ? keyof O extends keyof P
      ? ExtraPartial<InputParameter<P, keyof O>>
      : ExtraPartial<I>
    : Locale[K] extends Array<Record<infer Key, any> | string>
      ? { [key in LocaleKey<Locale> & Key]?: LocaleInputParameter<Locale, key, P>; }
      : Locale[K] extends Record<infer Key, any>
        ? { [key in LocaleKey<Locale> & Key]?: LocaleInputParameter<Locale, key, P>; }
        : string
);

export type LocaleOptionsParameter<
  Locale extends Translation,
  K extends LocaleKey<Locale>,
  P extends Processors,
> = null | (
  Locale[K] extends { processor: infer O; parameter: infer I; }
    ? keyof O extends keyof P
      ? ExtraPartial<OptionsParameter<P, keyof O>>
      : ExtraPartial<I>
    : Locale[K] extends Record<string, any>
      ? { [key in LocaleKey<Locale> & keyof Locale[K]]?: LocaleOptionsParameter<Locale, key, P>; }
      : string
);

export type TranslationFunction<Locale extends Translation, P extends Processors, R = string> = {
  /**
   * A translation function, looks for a specified key in the local translation document to provide a localized string.
   * @param key
   *  - a key from a translation document (usually `{locale}.json`)
   * @param input
   * -- a default value (in case a plain-string translation for the key isn't found)
   *  - an input parameter, if the locale key is parametrized
   * @returns a translated string
   */
  <K extends LocaleKey<Locale>>(
    key: K,
    input?: Locale[K] extends (...args: infer A) => string
      ? { args: A }
      : LocaleInputParameter<Locale, K, P> | null,
    parameter?: LocaleOptionsParameter<Locale, K, P>,
  ): R;
};

export type TranslationProxy<Locale extends Translation, P extends Processors> = TranslationFunction<Locale, P> & {
  [K in LocaleKey<Locale>]: string & (
    (input?: LocaleInputParameter<Locale, K, P>, parameter?: LocaleOptionsParameter<Locale, K, P>) => string
  );
} & {
  if(condition: boolean, otherwise?: string): TranslationFunction<Locale, P, string | undefined>;
};

const isProcessedKey = (k: object): k is ParametrizedTranslationRecord => 'processor' in k && typeof k['processor'] === 'object';

export const createTranslator: {

  <Locale extends Translation>(
    getLocaleDocument: () => Locale | undefined,
    currentLocaleId: () => Intl.Locale,
  ): TranslationProxy<Locale, typeof defaultProcessors>;

  <Locale extends Translation, P extends Processors>(
    getLocaleDocument: () => Locale | undefined,
    currentLocaleId: () => Intl.Locale,
    processors: P
  ): TranslationProxy<Locale, P>;

} = <Locale extends Translation>(
  getLocaleDocument: () => Locale | undefined,
  currentLocaleId: () => Intl.Locale,
  processors = defaultProcessors
) => {
  const localizedProcessors = Object.keys(processors).reduce((obj, key) => ({
    ...obj,
    [key]: processors[key as keyof typeof processors](currentLocaleId()),
  }), {} as Record<string, ReturnType<Processor>>);

  return new Proxy<TranslationProxy<Locale, Processors>>(
    function translate<K extends LocaleKey<Locale>>(
      key: K,
      input?: LocaleInputParameter<Locale, LocaleKey<Locale>, Processors>,
      parameter?: LocaleOptionsParameter<Locale, LocaleKey<Locale>, Processors>
    ): string {
      const doc = getLocaleDocument();
      const notFound: string = typeof input === 'string' ? input : key;

      if (!doc) {
        return notFound;
      }

      const currentKey = doc[key];

      if (Array.isArray(currentKey)) {
        const result = currentKey.map(refK => {
          if (typeof refK !== 'string') {
            return translate(
              Object.keys(refK)[0] as K,
              (input as Record<string, typeof input>)?.[Object.keys(refK)[0]],
              (parameter as Record<string, typeof parameter>)?.[Object.keys(refK)[0]]
            );
          }

          if (!refK.startsWith('input:')) {
            return translate(refK as K);
          }

          const _input = input as Record<string, typeof input>;
          const parametrizedRef = currentKey.find(k => typeof k === 'object' && refK === `input:${Object.keys(k)[0]}`);
          const inputKey = Object.keys(parametrizedRef ?? {})[0];

          return _input[inputKey];
        }).join(' ');

        // TODO: get rid of typecasting
        return result;
      }

      if (typeof currentKey === 'function') {
        if (typeof input === 'object' && input && 'args' in input && Array.isArray(input.args)) {
          return currentKey(...input.args);
        }

        return currentKey();
      }

      if (typeof currentKey !== 'object') {
        return currentKey ?? notFound;
      }

      if (!isProcessedKey(currentKey)) {
        return Object.keys(currentKey).map((refKey) => translate(
          refKey as LocaleKey<Locale>,
          (
            typeof input === 'object' && input
              ? mergeInputs(
                  currentKey[refKey],
                  input[refKey as keyof typeof input]
                )
              : currentKey[refKey]
          ) as LocaleInputParameter<Locale, LocaleKey<Locale>, Processors>
        )).join(' ');
      }

      const processor = localizedProcessors[Object.keys(currentKey.processor)[0]];

      if (!processor) {
        return notFound;
      }

      const mergedParameter = {
        ...currentKey.parameter,
        ...parameter as ParameterObject,
      };

      const intermediateResult = processor(mergedParameter, key, doc);

      // Delete undefined keys to make defaults bypass them in the spread later
      const mergedInput = mergeInputs(
        currentKey.input,
        input as InputObject
      );

      const result = intermediateResult(mergedInput, mergedParameter);

      return result ?? notFound;
    } as TranslationProxy<Locale, Processors>, {
      get(
        t: TranslationFunction<Locale, Processors>,
        prop: Parameters<typeof t>[0]
      ) {
        if (prop in t) {
          return t[prop as keyof typeof t];
        }

        if (prop === 'if') {
          return (condition: boolean, otherwise?: string) => (...args: Parameters<typeof t>) => {
            if (!condition) {
              return otherwise;
            }

            return t(...args);
          };
        }

        const processor = (input?: Parameters<typeof t>[1]) => t(prop, input);

        processor.toString = () => processor();

        return processor;
      },
    }
  );
};

function mergeInputs(
  baseInput: InputObject,
  input?: InputObject,
) {
  if (typeof input === 'object' && input != null) {
    for (const prop in input)
      if (input[prop] == null) {
        delete input[prop];
      }
  }

  const mergedInput = typeof baseInput === 'object' && typeof input === 'object'
    ? { ...baseInput, ...input }
    : (input ?? baseInput);

  return mergedInput;
}
