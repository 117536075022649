import { createLocaleResource } from 'shared/l10n/solid/resource';
import { fetchFirebaseLocale } from 'shared/firebase';

export const t = createLocaleResource<typeof import('./en.json')>(locale => (
  fetchFirebaseLocale(
    defaultLocale => import(`../locales/${locale.language}.json`)
      .catch(() => import(`../locales/${defaultLocale.language}.json`)),
    locale,
    'home'
  )
));
