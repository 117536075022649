import { getNumber } from 'firebase/remote-config';

import type { LocaleResponse } from '../l10n';

import { initializeApp, fetchAndActivate, getRemoteConfig, getString } from './foundation';

if (import.meta.env.DEV) {
  indexedDB.deleteDatabase('firebase_remote_config');
}

const defaultConfig = { apiKey: 'AIzaSyAzXfMjJ8FvhZp3FQATMICH-ndLjXNVUeg', authDomain: 'utasks-prod.firebaseapp.com', databaseURL: 'https://utasks-prod-default-rtdb.europe-west1.firebasedatabase.app', projectId: 'utasks-prod', storageBucket: 'utasks-prod.appspot.com', messagingSenderId: '5984125385', appId: '1:5984125385:web:a25ad2d9018c01ec712818' };

export const firebaseConfig = Promise.resolve<typeof defaultConfig>(
  import.meta.env.APP_FIREBASE_CONFIG
  ? JSON.parse(import.meta.env.APP_FIREBASE_CONFIG)
  : defaultConfig
);

export const app = firebaseConfig.then(initializeApp);
export const config = app.then(getRemoteConfig);
export const configFetching = config.then(fetchAndActivate).then(() => config).catch(() => config);

export const getConfigNumber = (key: string) => configFetching.then(c => getNumber(c, key));
export const getConfigString = (key: string) => configFetching.then(c => getString(c, key));
export const getLocaleUrl = (lang: string, path: string) => () => (
  firebaseConfig.then(config => [config.databaseURL, 'utf', lang, path + '.json'].join('/'))
);

export function fetchFirebaseLocale<Locale>(
  local: (defaultLocale: Intl.Locale) => Promise<LocaleResponse<Locale>>,
  locale: Intl.Locale,
  path: string
): Promise<LocaleResponse<Locale>> {
  return getConfigString('defaultlocale')
    .then(loc => local(new Intl.Locale(loc)))
    .then(r => ({
      default: r.default,
      remote: configFetching
        .then(getLocaleUrl(locale.language, path))
        .then(fetch)
        .then(r => r.json())
        .catch(() => r.default),
    }));
}
