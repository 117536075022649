export const isDateInPast = (d?: Date | null, relativeTo = new Date()) => !!d && (
  (d.getHours() !== 0 || d.getMinutes() !== 0)
    ? d.getTime() < relativeTo.getTime()
    : d.getFullYear() == relativeTo.getFullYear()
      ? d.getMonth() == relativeTo.getMonth()
        ? d.getDate() < relativeTo.getDate()
        : d.getMonth() < relativeTo.getMonth()
      : d.getFullYear() < relativeTo.getFullYear()
);

export const isDateEqual = (d?: Date | null, relativeTo = new Date()) => !!d && (
  d.getFullYear() == relativeTo.getFullYear()
  && d.getMonth() == relativeTo.getMonth()
  && d.getDate() == relativeTo.getDate()
);

export const today = () => new Date();
export const tomorrow = (_today = today()) => new Date(_today.getFullYear(), _today.getMonth(), _today.getDate() + 1);

export function normalizeDateTime(localDate: Date | null) {
  // TODO: rewrite when backend moves to UTC
  const shouldEraseTime = true || (localDate && (
    localDate?.getHours() === 0 && localDate?.getMinutes() === 0
  ));

  const normalDate = localDate && new Date(
    localDate.getFullYear(),
    localDate.getMonth(),
    localDate.getDate(),
    localDate.getHours(),
    shouldEraseTime
      ? localDate.getMinutes() - localDate.getTimezoneOffset()
      : localDate.getMinutes()
  );

  return normalDate;
}

export const hasNoTime = (date?: Date | null) => !!date && date.getHours() === 0 && date.getMinutes() === 0;

export const toRelativeTimeString = (date?: Date | null) => date?.toISOString().slice(0, -1);
